const developmentHosts = ['localhost', '127.0.0.1'];
const stagingHosts = ['cirium.dev', 'rbidev.ds', 'staging'];
const hideAccountHosts = [
  'www.cirium.com',
  'discover.cirium.com',
  'developer.cirium.com',
  'developer.studios.cirium.dev',
  'developer.studios.cirium.io',
  'auth.cirium.rbi.web.internal',
  'cirium.uat.cja.rbxd.ds',
];

const shouldHideAccount = () => {
  const host = window.location.hostname;
  return !!hideAccountHosts.find((dontShowAccountAtTheseHostnames) => {
    return host.toLowerCase() === dontShowAccountAtTheseHostnames;
  });
};

export const isProduction = () => {
  const host = window.location.hostname;

  return !(
    developmentHosts.includes(host) ||
    stagingHosts.find((h) => {
      return host.includes(h);
    })
  );
};

export const common = {
  debounce: 200,
  shadowMode: 'closed',
  logColor: 'dodgerBlue',
  logEnabled: false,
  profileAPIPath: '/api/global-header-content',
  hideAccount: shouldHideAccount(),
};

export const staging = {
  myCiriumHost: 'https://my-cirium.studios.cirium.dev',
};

export const production = {
  myCiriumHost: 'https://my.cirium.com',
};

export const config = {
  ...common,
  ...(isProduction() ? production : staging),
};

export default config;
