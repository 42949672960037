import BaseElement from '../BaseElement/index.js';
import {
  constructStyle,
  airframeStandardSheet,
  getModulePath,
} from '../BaseElement/util.js';

const modulePath = getModulePath(import.meta.url);

const style = constructStyle(`
  .af-global-header-button__button {
    min-height: var(--airframe-space-xxl);
    min-width: auto;
    padding: 0px;
    margin: 0px;
    color: var(--airframe-color-neutral-white);
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .4px;
  }

  button {
    margin-left: 0px !important; 
    padding-right: var(--airframe-space-sm) !important;
  }

  ::slotted(span){
    vertical-align: middle;
  }

  :host([last]) button {
    padding-right: 0px !important;
  }

  :host([breakpoint=sm]) button {
    margin: 0px !important;
    padding: 0px !important;
  }

  :host([breakpoint=sm]) ::slotted(span){
    display: none;
  }
  :host([breakpoint=vs]) ::slotted(span){
    display: none;
  }
`);

const template = (vars) => `
  <button type="button" class="airframe__button af-global-header-button__button">
    <slot name="icon">${vars.icon}</slot>
    <slot name="label">${vars.label}</slot>
  </button>
`;

export class Button extends BaseElement {
  constructor() {
    super();
    this.adoptStyle(airframeStandardSheet);
    this.adoptStyle(style);
  }
  connectedCallback() {
    this.importTemplate(template);
    this.render();
    super.connectedCallback();
  }
  static get observedAttributes() {
    return ['icon', 'label', 'last'];
  }
  attributeChangedCallback(name, oldVal, newVal) {
    if (oldVal !== newVal) {
      this.render();
    }
  }

  get icon() {
    return this.getAttribute('icon');
  }
  set icon(val) {
    this.setAttribute('icon', val);
  }
  get label() {
    return this.getAttribute('label');
  }
  set label(val) {
    this.setAttribute('label', val);
  }
  get last() {
    return this.hasAttribute('last');
  }
  set last(val) {
    if (val) {
      this.setAttribute('last', '');
    } else {
      this.removeAttribute('last');
    }
  }
  focus() {
    this.shadow.querySelector('button').focus();
  }
  render() {
    this.innerHTML = `
      <${this.icon} slot="icon"></${this.icon}>
      <span slot="label">${this.label}</span>
    `;
  }
}

window.customElements.define('af-global-header-button', Button);
