import config from '../config.js';
export const logColor = 'orange';
export const logEnabled = false;

export class ProfileClient {
  // were using per-config singletons to avoid
  // any unnecessary spin up costs from repeated use.

  constructor(config) {
    return this._registerInstance(config);
  }

  static _instanceRegistry = {};

  async _registerInstance(config) {
    //TODO: crc32 hash maybe?
    const instanceKey = Object.keys(config)
      .sort()
      .map((k) => k + '=' + config[k])
      .join('&');

    const registry = ProfileClient._instanceRegistry;

    if (!registry[instanceKey]) {
      registry[instanceKey] = this;
      this.logger('New client');

      this.domain = config.domain;
      this.clientId = config.clientId;
      this.audience = config.audience;
      this.loginUrl = config.loginUrl;
      this.logoutUrl = config.logoutUrl;
      this.profileToken = config.profileToken;
      this.returnTo = config.returnTo;
      let authenticated = false;
      //auth0 logic here;
      if (this.domain && this.clientId) {
        this.auth0Client = await this._buildAuth0Client();
        await this._handleRedirectCallback();
        authenticated = await this.auth0Client.isAuthenticated();
      } else if (
        (await this.loginUrl) &&
        (await this.logoutUrl) &&
        (await this.profileToken)
      ) {
        authenticated = config.authenticated;
      }
      this.authenticated = authenticated;
    } else {
      this.logger('Using existing client');
    }
    return registry[instanceKey];
  }

  logger(msg, type = 'log') {
    if (logEnabled) {
      console[type].call(
        window,
        `%c[Profile Client] ${msg}`,
        `color:${this.logColor || logColor}`,
      );
    }
  }

  async _buildAuth0Client() {
    const { default: createAuth0Client } = await import('@auth0/auth0-spa-js');

    const auth0 = await createAuth0Client({
      domain: this.domain,
      client_id: this.clientId,
      audience: this.audience,
    });

    try {
      await auth0.getTokenSilently();
    } catch (e) {
      this.logger(e.message, 'error');
    }

    return auth0;
  }

  async getProfile() {
    const token = await this.profileToken;
    return await fetch(`${config.myCiriumHost}${config.profileAPIPath}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      async (res) => {
        return await res.json().then((profile) => {
          this.addMetaTags(profile);
          return profile;
        });
      },
      (e) => {
        this.logger(e.error, 'error');
      },
    );
  }

  async login() {
    await this.auth0Client.loginWithRedirect({
      redirect_uri: `${window.location.origin}`,
    });
  }

  async logout() {
    await this.auth0Client.logout({
      returnTo: this.returnTo,
    });
  }

  createMetaTag(name, value) {
    let tag = document.querySelector(`meta[name=${name}]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.setAttribute('name', name);
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', value);
  }

  addMetaTags(user) {
    if (user.viperGuid) {
      this.createMetaTag('gwa_userID', user.viperGuid);
    }
    this.createMetaTag('gwa_localVar2', user.userId);
  }

  async _handleRedirectCallback() {
    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      await this.auth0Client.handleRedirectCallback();
      //window.location.hash = window.location.hash; // workaround for FF/Edge
      window.history.replaceState({}, document.title, '/');
    }
  }

  get logoutUrl() {
    const url = this._logoutUrl;
    if (!url && this.auth0Client) {
      return this.auth0Client.buildLogoutUrl({
        returnTo: this.returnTo,
      });
    } else {
      return Promise.resolve(url);
    }
  }

  set logoutUrl(val) {
    this._logoutUrl = val;
    return this;
  }

  get loginUrl() {
    const url = this._loginUrl;
    if (!url && this.auth0Client) {
      return this.auth0Client.buildAuthorizeUrl({
        redirect_uri: config.myCiriumHost,
      });
    } else {
      return Promise.resolve(this._loginUrl);
    }
  }
  set loginUrl(val) {
    this._loginUrl = val;
    return this;
  }

  get signupUrl() {
    return Promise.resolve(this.loginUrl).then((url) =>
      url ? `${url}&screen_hint=signup` : null,
    );
  }

  get authenticated() {
    return this.auth0Client
      ? this.auth0Client.isAuthenticated()
      : Promise.resolve(this._authenticated);
  }

  set authenticated(val) {
    this._authenticated = val;
  }

  get profileToken() {
    let token = this._profileToken;
    if (!token && this.auth0Client) {
      return this.auth0Client.getTokenSilently({
        audience: 'cae-user-services',
      });
    } else {
      return Promise.resolve(token);
    }
  }

  set profileToken(val) {
    this._profileToken = val;
    return this;
  }
}

export default ProfileClient;
