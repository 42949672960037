import 'construct-style-sheets-polyfill';
// import GoogleFontsLoader from 'google-fonts-loader';
import {
  resetCss,
  standardCss,
  variablesCss,
} from '../AirframeStandard/index.js';

// GoogleFontsLoader.load({ Montserrat: ['400', '500', '600'] });

export const getModulePath = (url) => {
  const urlObj = new URL(url);
  return `${urlObj.origin}${urlObj.pathname.split('/').slice(0, -1).join('/')}`;
};

export const createTemplate = (templateString) => {
  const template = document.createElement('template');
  template.innerHTML = `${templateString}`;
  return template;
};

export const fetchExternalCss = async (path) => {
  return await fetch(path).then(async (res) => {
    let cssText = String(await res.text());

    if (cssText.includes('@import')) {
      console.warn(
        `@imports are currently disallowed due to polyfill limitations.  \nThey will be removed automatically from "${path}".\nPlease use constructable styles instead.`,
      );
      cssText = cssText.replace(/@import(.*);/g, '');
    }
    if (cssText.includes('sourceMappingURL')) {
      console.warn(
        `Removing source maps from "${path}" to reduce requests and potential console warnings.`,
      );
      cssText = cssText.replace(/^\/\*# sourceMappingURL=(.*)$/, '');
    }

    return String(cssText);
  });
};

export const loadExternalCss = async (path) => {
  return await fetchExternalCss(path).then(async (rawCss) => {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(rawCss);
    return sheet;
  });
};

export const constructStyle = (cssMarkup) => {
  const style = new CSSStyleSheet();
  style.replaceSync(cssMarkup);
  return style;
};

export const airframeVariablesSheet = constructStyle(variablesCss);
export const airframeResetSheet = constructStyle(resetCss);
export const airframeStandardSheet = constructStyle(standardCss);
