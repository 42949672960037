import { BaseElement } from "../BaseElement/index.js";
import { constructStyle } from "../BaseElement/util.js";
import config, { isProduction } from "../config.js";
import "../Dropdown/index.js";
import { ProfileClient, ProfileContext } from "../CiriumProfile/index.js";

/* px value reference from from airframe theme
space.xxxs = rem(2);
space.xxs = rem(4);
space.xs = rem(8);
space.sm = rem(12);
space.md = rem(16);
space.lg = rem(24);
space.xl = rem(32);
space.xxl = rem(40);
space.xxxl = rem(48);
*/

const style = constructStyle(`
    :host {
      display: block;
        background-color: var(--airframe-color-neutral-grey-80);
        height: var(--airframe-space-xxl);
      position: relative;
      text-align: right;
      border-bottom: 1px var(--airframe-color-neutral-grey-40) solid !important;
      padding: 0px var(--airframe-space-xs) !important;
    }
    
    :host([breakpoint=sm]) {
      padding: 0px var(--airframe-space-sm) !important;
    }
    
    :host([breakpoint=md]),
    :host([breakpoint=lg]) {
      padding: 0px var(--airframe-space-md) !important;
    }

    :host([breakpoint=sm]) af-global-header-spacer {
      display: none;
    }
`);

const headerTemplate = (vars = {}) => `
  ${
    !vars.placeHolder
      ? `
        <af-launcher-dropdown 
          products="${
            vars.user?.products?.map((p) => `${p.title}|${p.url}`).join(",") ||
            ""
          }"
        ></af-launcher-dropdown>
        <af-global-header-spacer></af-global-header-spacer>
        <af-help-dropdown></af-help-dropdown>
        ${
          config.hideAccount
            ? ""
            : `<af-global-header-spacer></af-global-header-spacer>
        <af-account-dropdown></af-account-dropdown>`
        }
    `
      : ``
  }
  
`;

export class GlobalHeader extends BaseElement {
  constructor() {
    super();
    const font = document.createElement("link");
    font.href = isProduction() ?
      "https://airframe-static.cirium.com/static/fonts/montserrat/css.css" : "https://airframe-static.studios.cirium.dev/static/fonts/montserrat/css.css";
    font.rel = "stylesheet";
    document.head.appendChild(font);
  }
  async connectedCallback() {
    await this.render();
    super.connectedCallback();
    this.adoptStyle(style);
  }

  static observedAttributes = ["authenticated"];

  async attributeChangedCallback(_, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  async render() {
    let authenticated = false;

    // self assign to apply the zIndex
    this.elevate = this.elevate;

    this.importTemplate(headerTemplate, {}, true);

    if (!config.hideAccount) {
      this.profileClient = await new ProfileClient({
        //Client-side Auth0
        domain: this.domain,
        clientId: this.clientId,
        audience: this.audience,
        returnTo: this.returnTo,
        //Server-side auth0
        loginUrl: this.loginUrl,
        logoutUrl: this.logoutUrl,
        profileToken: this.profileToken,
        authenticated: this.authenticated,
      });

      ProfileContext.value = this.profileClient;
    }
  }

  get domain() {
    return this.getAttribute("domain");
  }

  set domain(val) {
    this.setAttribute("domain", val);
  }

  get returnTo() {
    return this.getAttribute("returnto");
  }

  set returnTo(val) {
    this.setAttribute("returnto", val);
  }

  get elevate() {
    if (this.hasAttribute("elevate")) {
      const val = this.getAttribute("elevate")?.toLowerCase();
      if (val === "false") {
        return false;
      } else if (val !== "true") {
        return parseInt(val);
      }
    }
    return 99999;
  }

  set elevate(val) {
    if (val) {
      this.setAttribute("elevate", val);
    } else {
      this.setAttribute("elevate", "false");
    }
    this.style.zIndex = this.elevate || "initial";
  }

  get clientId() {
    return this.getAttribute("clientid");
  }

  set clientId(val) {
    this.setAttribute("clientid", val);
  }

  get audience() {
    return this.getAttribute("audience") || "my-cirium";
  }

  set audience(val) {
    this.setAttribute("audience", val);
  }

  get authenticated() {
    return this.hasAttribute("authenticated");
  }

  set authenticated(val) {
    if (val) {
      this.setAttribute("authenticated", "");
    } else {
      this.removeAttribute("authenticated");
    }
  }

  get loading() {
    return this.hasAttribute("loading");
  }

  set loading(val) {
    if (val) {
      this.setAttribute("loading", "");
    } else {
      this.removeAttribute("loading");
    }
  }

  get profileToken() {
    return this.getAttribute("profiletoken");
  }

  set profileToken(val) {
    this.setAttribute("profiletoken", val);
  }

  get logoutUrl() {
    return this.getAttribute("logouturl");
  }

  set logoutUrl(val) {
    this.setAttribute("logouturl", val);
  }

  get loginUrl() {
    return this.getAttribute("loginurl");
  }

  set loginUrl(val) {
    this.setAttribute("loginurl", val);
  }
}

const spacerStyle = constructStyle(`
  :host {
    vertical-align: top;
    display: inline-block;
    background-color: var(--airframe-color-neutral-white);
    height: var(--airframe-space-lg);
    width: var(--airframe-space-xxxs);
    margin: var(--airframe-space-xs) 0px;
  }
`);

const spacerTemplate = () => `
  <div></div>
`;

export class GlobalHeaderSpacer extends BaseElement {
  constructor() {
    super();
    this.adoptStyle(spacerStyle);
    this.importTemplate(spacerTemplate);
  }
}

window.customElements.define("af-global-header", GlobalHeader);
window.customElements.define("af-global-header-spacer", GlobalHeaderSpacer);

export default GlobalHeader;
