import "construct-style-sheets-polyfill";
import {
  createTemplate,
  airframeResetSheet,
  airframeVariablesSheet,
} from "./util.js";
import variables from "../AirframeStandard/index.js";

import config from "../config.js";
export const shadowMode = config.shadowMode;
export const logColor = config.logColor;
export const logEnabled = config.logEnabled;

export class BaseElement extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: this.getShadowMode() });
    this.applyBreakpoint = this.applyBreakpoint.bind(this);
    this.getCssBreakpoints = this.getCssBreakpoints.bind(this);
  }

  adoptStyle(cssom) {
    this.shadow.adoptedStyleSheets = [...this.shadow.adoptedStyleSheets, cssom];
  }

  // making shadows only closed when they are the topmost shadow root.
  getShadowMode() {
    // Note: Updating this as a test to see of we'll be able to see the global header in FullStory.
    return 'open';
    //return this.closest("body") ? "closed" : "open";
  }

  importTemplate(template, vars, overwrite = false) {
    const clone = document.importNode(
      createTemplate(template({ ...this.getAttributes(), ...vars })).content,
      true
    );
    if (overwrite) {
      Array.from(this.shadow.children).forEach((c) => {
        // don't touch existing style, or safari breaks.
        // seriously don't even store a reference to it,
        // or it will fail to apply new styles properly.
        if (!c.nodeName || c.nodeName.toLowerCase() !== "style") {
          this.shadow.removeChild(c);
        }
      });
    }

    this.shadow.appendChild(clone);
  }

  getAttributes() {
    return this.getAttributeNames().reduce((attrs, key) => {
      attrs[key] = this.getAttribute(key);
      return attrs;
    }, {});
  }

  getStyle(modulePath) {
    return Promise.all([this.loadExternalCss(`${modulePath}/styles.css`)]).then(
      () => {
        // needs to happen after style is appended
        this.applyBreakpoint();
      },
      (e) => {
        this.logger(
          `Failed to load external file ${e.currentTarget.href}`,
          "error"
        );
      }
    );
  }

  connectedCallback() {
    this.logger("mounted");
    this.applyBreakpoint();

    this.adoptStyle(airframeVariablesSheet);
    this.adoptStyle(airframeResetSheet);
    window.addEventListener("resize", this.applyBreakpoint);
  }

  disconnectedCallback() {
    this.logger("unmounted");
    window.removeEventListener("resize", this.applyBreakpoint);
  }

  loadExternalCss(path = "", type = "stylesheet") {
    return new Promise((res, rej) => {
      const link = document.createElement("link");
      link.onload = res;
      link.onerror = rej;
      link.href = path;
      link.rel = type;
      this.shadow.appendChild(link);
    });
  }

  logger(msg, type = "log") {
    if (logEnabled) {
      console[type].call(
        window,
        `%c[${this.nodeName}] ${msg}`,
        `color:${this.logColor || logColor}`
      );
    }
  }

  get isTouch() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  get cssPrefix() {
    return this.nodeName.toLocaleLowerCase();
  }

  async applyBreakpoint() {
    const scope = BaseElement.prototype;

    if (!scope.__airframeBreakpointsPromise) {
      scope.__airframeBreakpointsPromise = this.getCssBreakpoints();
    }

    scope.__airframeBreakpoints = await scope.__airframeBreakpointsPromise;

    const width = window.document.documentElement.clientWidth;
    const bands = Object.keys(scope.__airframeBreakpoints)
      .sort((a, b) => {
        return scope.__airframeBreakpoints[a] - scope.__airframeBreakpoints[b];
      })
      .map((key, i, sortedKeys) => {
        return {
          start: scope.__airframeBreakpoints[sortedKeys[i - 1]] || 0,
          end: scope.__airframeBreakpoints[sortedKeys[i]],
          key: key,
        };
      });

    let activePoint;
    let i = 0;
    for (i = 0; i < bands.length; i++) {
      if (width > bands[i].start) {
        activePoint = bands[i].key;
      } else {
        break;
      }
    }

    this.breakpoint = activePoint;
    const breakpointClass = `${this.cssPrefix}--breakpoint--${activePoint}`;
    if (!this.classList.contains(breakpointClass)) {
      if (this._previousBreakpoint) {
        this.classList.remove(this._previousBreakpoint);
      }
      this._previousBreakpoint = breakpointClass;
      this.classList.add(breakpointClass);
    }
    return true;
  }

  async getCssBreakpoints() {
    const vars = variables;

    const breakpoints = Object.keys(vars).reduce((breakpoints, key) => {
      const val = vars[key];
      if (key.startsWith("airframe-breakpoint")) {
        breakpoints[key.split("-").pop()] = parseInt(val);
      }
      return breakpoints;
    }, {});

    return breakpoints;
  }

  get breakpoint() {
    return String(this.getAttribute("breakpoint")) || null;
  }

  set breakpoint(val) {
    this.setAttribute("breakpoint", String(val) || null);
  }
}

export default BaseElement;
