const store = new WeakMap();

class Context {
  constructor(value) {
    this.value = value;
  }
  get value() {
    return store.get(this);
  }
  set value(val) {
    const oldValue = this.value;
    const newValue = val;
    store.set(this, val);
    this.listeners.forEach((listener) => {
      if (listener.contextChangedCallback) {
        listener.contextChangedCallback.apply(listener, [newValue, oldValue]);
      }
    });
  }

  listeners = new Set();

  subscribe(listener) {
    if (this.listeners.has(listener)) {
      this.unsubscribe(listener);
    }
    this.listeners.add(listener);
  }
  unsubscribe(listener) {
    this.listeners.delete(listener);
  }

  withContext(Super) {
    const context = this;
    return class extends Super {
      constructor() {
        super();
      }
      connectedCallback() {
        context.subscribe(this);
        return super.connectedCallback();
      }
      disconnectedCallback() {
        context.unsubscribe(this);
        return super.disconnectedCallback();
      }
      get context() {
        return context.value;
      }
      set context(value) {
        context.value = value;
        return this;
      }
    };
  }
}

export function createContext(initialValue = '') {
  return new Context(initialValue);
}
