import { BaseElement } from '../BaseElement/index.js';
import {
  airframeStandardSheet,
  constructStyle,
  getModulePath,
} from '../BaseElement/util.js';
import '../SVGIcon/index.js';

const modulePath = getModulePath(import.meta.url);

const listStyle = constructStyle(`
  :host {
    h5 {
      display: block;
      background-color: var(--airframe-color-neutral-grey-30);
    }
  }
`);

const listTemplate = (vars) => `
  <ul>
    <slot><slot>
  </ul>
`;

export class LinkList extends BaseElement {
  constructor() {
    super();
    this.adoptStyle(airframeStandardSheet);
    this.adoptStyle(listStyle);
    this.classList.add('af-link-list');
    this.importTemplate(listTemplate);
  }
}

const listItemStyle = constructStyle(`
  :host {
    display: list-item;
  }
  .af-link-list__link,
  .af-link-list__link:visited,
  .af-link-list__link:hover {
    font-size: 14px;
    letter-spacing: .4px;
    color: var(--airframe-color-neutral-black);
    display: block;
    line-height: var(--airframe-space-xl);
    padding: 0px var(--airframe-space-lg);
    text-decoration: none;
  }

  .af-link-list__link:hover,
  .af-link-list__link:focus {
    background-color: rgba(0,193,159,.25)
  }
`);

const listItemTemplate = (vars) => `
  <a class="airframe__link af-link-list__link" href="${
    vars.href || 'javascript:void(0);'
  }" target="${vars.target || ''}">
    <slot></slot>
  </a>
`;

export class LinkListItem extends BaseElement {
  constructor() {
    super();
    this.adoptStyle(airframeStandardSheet);
    this.adoptStyle(listItemStyle);
    this.classList.add('af-link-list__item');
  }
  connectedCallback() {
    this.importTemplate(listItemTemplate);
    this._linkRef = this.shadow.querySelector('.af-link-list__link');
  }
  static get observedAttributes() {
    return ['href', 'target'];
  }
  attributeChangedCallback(name, oldVal, newVal) {
    if (this._linkRef && oldVal !== newVal) {
      this._linkRef.setAttribute(name, newVal);
    }
  }
  get focused() {
    return this._linkRef === this.shadow.activeElement;
  }
  set focused(val) {
    if (val) {
      this.focus();
    } else {
      this.blur();
    }
  }
  focus() {
    this._linkRef.focus();
  }
  blur() {
    this._linkRef.blur();
  }
  get href() {
    return this.getAttributes('href');
  }
  set href(val) {
    this.setAttributes('href', val);
  }
  get target() {
    return this.getAttributes('target');
  }
  set target(val) {
    this.setAttributes('target', val);
  }
}

const listHeadingStyle = constructStyle(`
  :host {
    display: list-item;
    font-weight: 600;
    color: var(--airframe-color-primary-extra-dark);
    text-transform: uppercase;
    font-family: var(--font-family-montserrat);
    font-size: 12px;
    letter-spacing: .4px;
    line-height: 24px;
    padding: 0px;
    margin: 0px var(--airframe-space-sm);
    border-top: 1px var(--airframe-color-neutral-grey-20) solid;
  }

  :host(:first-child) {
    border-style: none;
  }
`);

const listHeadingTemplate = (vars) => `
  <slot></slot>
`;

export class LinkListHeading extends BaseElement {
  constructor() {
    super();
    this.adoptStyle(airframeStandardSheet);
    this.adoptStyle(listHeadingStyle);
    this.classList.add('af-link-list__heading');
    this.importTemplate(listHeadingTemplate);
  }
}

window.customElements.define('af-link-list', LinkList);
window.customElements.define('af-link-list-item', LinkListItem);
window.customElements.define('af-link-list-heading', LinkListHeading);
