import BaseElement from '../BaseElement/index.js';
import { constructStyle, airframeStandardSheet } from '../BaseElement/util.js';
import '../Button/index.js';
import '../SVGIcon/index.js';
import { LinkList } from '../LinkList/index.js';
import config, { isProduction } from '../config.js';
import { ProfileContext } from '../CiriumProfile/index.js';

const style = constructStyle(`
  :host, :root {
    --menu-width : 245px;
    position: relative;
    display: inline-block;
  }

  ::slotted(af-global-header-button) {
    display: inline-block;
    height: var(--airframe-space-xxl);
  }

  .af-global-header-dropdown__panel {
    display: none;
    position: absolute;
    min-width: var(--menu-width);
    box-shadow: var(--airframe-color-neutral-grey-45-opacity-75) 0px var(--airframe-space-xxxs) var(--airframe-space-xxs) 0px;
    border-bottom: 6px solid var(--airframe-color-primary-normal);
    background-color: var(--airframe-color-neutral-white);
    text-align: left;
  }

  :host([breakpoint=sm]) .af-global-header-dropdown__panel {
    min-width: auto;
    position: fixed;
    left : 0px;
    right : 0px;
    overflow: auto;
  }

  :host([open]) .af-global-header-dropdown__panel {
    display: block !important;
  }
`);

const template = () => `
  <slot name="label"></slot>
  <div class="af-global-header-dropdown__panel">
    <slot name="panel"></slot>
  </div>
`;

export class Dropdown extends BaseElement {
  constructor() {
    super();
    this.registerDropdown();

    this.adoptStyle(airframeStandardSheet);
    this.adoptStyle(style);

    this.importTemplate(template);
    this._panelRef = this.shadow.querySelector('.af-global-header-dropdown__panel');
    this.render();

    this.handleEvents = this.handleEvents.bind(this);
  }
  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  static get observedAttributes() {
    return ['icon', 'label', 'panel'];
  }

  connectedCallback() {
    this.classList.add('af-global-header-dropdown');

    this.addEventListener('pointerup', this.handleEvents);
    this.addEventListener('mouseenter', this.handleEvents);
    this.addEventListener('mouseleave', this.handleEvents);
    this.addEventListener('keydown', this.handleEvents);

    window.addEventListener('keydown', this.handleEvents);
    window.addEventListener('pointerup', this.handleEvents);

    BaseElement.prototype.connectedCallback.call(this);
  }

  disconnectedCallback() {
    this.removeEventListener('pointerup', this.handleEvents);
    this.removeEventListener('mouseenter', this.handleEvents);
    this.removeEventListener('mouseleave', this.handleEvents);
    this.removeEventListener('keydown', this.handleEvents);

    window.removeEventListener('keydown', this.handleEvents);
    window.removeEventListener('pointerup', this.handleEvents);

    super.disconnectedCallback();
  }

  focusDropdownByIndex(index) {
    const menus = Array.from(this.parentNode.querySelectorAll('.af-global-header-dropdown'));
    const newTarget = menus[index];
    if (newTarget) {
      newTarget.focus();
      if (this.open) {
        newTarget.open = true;
      }
    }
  }

  nextDropdown() {
    const menus = Array.from(this.parentNode.querySelectorAll('.af-global-header-dropdown'));
    const pos = menus.indexOf(this);
    this.focusDropdownByIndex((pos + 1) % menus.length);
  }

  previousDropdown() {
    const menus = Array.from(this.parentNode.querySelectorAll('.af-global-header-dropdown'));
    const pos = menus.indexOf(this);
    this.focusDropdownByIndex((pos ? pos : menus.length) - 1);
  }

  menuUp() {
    this.open = true;
    const listItems = this.getMenuItems();
    const focused = this.getFocusedMenuItem();
    const pos = listItems.indexOf(focused);
    (listItems[pos - 1] || listItems[listItems.length - 1]).focus();
  }

  menuDown() {
    this.open = true;
    const listItems = this.getMenuItems();
    const focused = this.getFocusedMenuItem();
    const pos = listItems.indexOf(focused);
    (listItems[pos + 1] || listItems[0]).focus();
  }

  getMenuItems() {
    return Array.from(this.querySelectorAll('.af-link-list__item'));
  }

  getFocusedMenuItem() {
    return this.getMenuItems().find((item) => {
      return item.focused;
    });
  }

  gotoMenuItem(item) {
    item._linkRef.click();
  }

  async handleEvents(e) {
    const isNavEvent = this.contains(e.target);

    if (isNavEvent) {
      switch (e.type) {
        case 'pointerup':
          if (e.target.nodeName.toUpperCase() === 'AF-LINK-LIST-ITEM') {
            e.preventDefault();
            this.gotoMenuItem(e.target);
          } else {
            e.stopImmediatePropagation();
            this.open = !this.open;
          }
          break;
        case 'mouseenter':
          if (!this.open) {
            clearTimeout(this._debounceTimer);
            this.open = true;
          }
          break;
        case 'mouseleave':
          this._debounceTimer = setTimeout(() => {
            this.open = false;
          }, config.debounce);
          break;
        case 'keydown':
          e.stopPropagation();
          switch (e.keyCode) {
            case 27: //escape
              this.activeDropdown.open = false;
              break;
            case 13: // enter
            case 32: // space
              const focused = this.getFocusedMenuItem();
              if (focused) {
                this.gotoMenuItem(focused);
              } else {
                this.open = !this.open;
              }
              break;
            case 37: // left
              e.preventDefault();
              this.previousDropdown();
              break;
            case 39: // right
              e.preventDefault();
              this.nextDropdown();
              break;
            case 38: // up
              e.preventDefault();
              this.menuUp();
              break;
            case 40: // down
              e.preventDefault();
              this.menuDown();
              break;
            default:
          }
          break;
        default:
      }
    } else if (Dropdown.prototype._activeDropdown?.open) {
      Dropdown.prototype._activeDropdown.open = false;
    }
  }

  get icon() {
    return this.getAttribute('icon');
  }

  set icon(val) {
    this.setAttribute('icon', val);
  }

  get label() {
    return this.getAttribute('label');
  }

  set label(val) {
    this.setAttribute('label', val);
  }

  get panel() {
    return this._panel || '';
    //return this.getAttribute('panel');
  }

  set panel(val) {
    this._panel = val || '';
    //this.setAttribute('panel', val);
  }

  get breakpoint() {
    return this.getAttribute('breakpoint');
  }

  set breakpoint(val) {
    this.setAttribute('breakpoint', val);
  }

  get open() {
    return this.hasAttribute('open');
  }

  set open(val) {
    if (val) {
      this.positionPanel();
      if (this.activeDropdown) {
        this.activeDropdown.open = false;
      }
      this.activeDropdown = this;
      this.setAttribute('open', '');
    } else {
      this.removeAttribute('open');
    }
  }

  get activeDropdown() {
    return Dropdown.prototype._activeDropdown || null;
  }

  set activeDropdown(dropdown) {
    Dropdown.prototype._activeDropdown = dropdown;
    return this;
  }

  get dropdownList() {
    return Dropdown.prototype._menuRegistry || [];
  }

  registerDropdown() {
    Dropdown.prototype._menuRegistry = Dropdown.prototype._menuRegistry || [];
    Dropdown.prototype._menuRegistry.push(this);
    return this;
  }

  positionPanel() {
    this._panelRef.style.visibility = 'hidden';
    this._panelRef.style.display = 'block';

    if (this.breakpoint !== 'sm') {
      const viewportWidth = window.document.documentElement.clientWidth;
      const box = this.getBoundingClientRect();
      const position =
        box.left + box.width / 2 > viewportWidth / 2 + box.width / 2
          ? 'left'
          : 'right';
      const positionClass = `af-global-header-dropdown--menu-position--${position}`;

      if (!this.classList.contains(positionClass)) {
        if (this._previousPosition) {
          this.classList.remove(this._previousPosition);
        }
        this.classList.add(positionClass);
        this._previousPosition = positionClass;
      }
    }

    if (
      this.breakpoint !== 'sm' &&
      this.classList.contains('af-global-header-dropdown--menu-position--left')
    ) {
      const buttonWidth = this.getBoundingClientRect().width;
      const menuWidth = this._panelRef.getBoundingClientRect().width;
      this._panelRef.style.marginLeft = (menuWidth - buttonWidth) * -1 + 'px';
    } else {
      this._panelRef.style.marginLeft = '0px';
    }

    this._panelRef.style.display = '';
    this._panelRef.style.visibility = '';
  }

  focus() {
    this._buttonRef.focus();
  }

  render() {
    this.innerHTML = `
      <af-global-header-button slot="label" icon="${this.icon}" label="${this.label}" ${
      this.last ? 'last' : ''
    }></af-global-header-button>
      ${this.panel}
    `;
    this._buttonRef = this.querySelector('af-global-header-button');
  }
}

export class LauncherDropdown extends Dropdown {
  constructor() {
    super();
    this.label = 'Cirium apps';
    this.icon = 'af-svg-icon-dialpad';
    this.panel = `<af-launcher-menu slot='panel'></af-launcher-menu>`;
    this.render();
  }
}

export class HelpDropdown extends Dropdown {
  constructor() {
    super();
    this.label = 'Help';
    this.icon = 'af-svg-icon-help';
    this.panel = "<af-help-menu slot='panel'></af-help-menu>";
    this.render();
  }
}

export class AccountDropdown extends Dropdown {
  constructor() {
    super();
    this.last = true;
    this.label = 'Account';
    this.icon = 'af-svg-icon-account';

    this.panel = `<af-account-menu slot='panel'></af-account-menu>`;
    this.render();
  }
  get name() {
    return this.getAttribute('name');
  }
  set name(val) {
    this.setAttribute('name', val);
  }
  get company() {
    return this.getAttribute('company');
  }
  set company(val) {
    this.setAttribute('company', val);
  }
}

// Cirium Apps menu items are products sentence casing does not apply here
export const appLinks = [
  {
    label: 'Dashboard',
    href: 'https://dashboard.cirium.com/app/#/',
  },
  {
    label: 'Developer Studio',
    href: isProduction()
      ? 'https://developer.cirium.com/'
      : 'https://developer.studios.cirium.dev/',
  },
  {
    label: 'Profiles',
    href: 'https://profiles.cirium.com/',
  },
  {
    label: 'Ground Events',
    href: isProduction()
      ? 'https://groundevents.cirium.com'
      : 'https://groundevents.cirium.com'
  },
  {
    label: 'Market Alerts',
    href: isProduction()
      ? 'https://market-alerts.cirium.com/alerts'
      : 'https://market-alerts.staging.cirium.io',
  },
  {
    label: 'Diio Mi',
    href: isProduction()
      ? 'https://mi.diio.net/mi/pages/login.jsp'
      : 'https://mipreview.diio.net/mi',
  },
  {
    label: 'DIIO beta',
    href: isProduction()
    ? 'https://diio.cirium.com'
    : 'https://snapshots.cps.cirium.dev',
  },
  {
    label: 'Fleets Analyzer',
    href: 'https://dashboard.cirium.com/app/fleet/#/analyserhome',
  },
  {
    label: 'FlightStats',
    href: 'https://flightstats.com/v2/account/login',
  },
  {
    label: 'Schedule Snapshot',
    href: isProduction()
      ? 'https://diio.cirium.com/snapshots'
      : 'https://snapshots.cps.cirium.dev/snapshots',
  },
  {
    label: 'SRS Analyzer',
    href: isProduction()
      ? 'https://srsanalyser.diio.net/srs/pages/login.jsp'
      : 'https://srspreview.diio.net/srs/login',
  },
  {
    label: 'Trip Center',
    href: 'https://trip.flightstats.com/login',
  },
  {
    label: 'Values Analyzer',
    href: 'https://dashboard.cirium.com/app/fleet/#/values/analyserhome',
  },
  {
    label: 'Value Trends',
    href: isProduction()
      ? 'https://profiles.cirium.com/valuetrends'
      : 'https://profiles.staging.aea.cirium.dev/valuetrends',
  },
  {
    label: 'Asset Watch',
    href: isProduction()
      ? 'https://riskanalytics.cirium.com'
      : 'https://riskanalytics.gemini.aea.cirium.dev/portfolios',
  }
];

const ciriumLinks = [
  {
    label: 'Cirium.com',
    href: 'https://cirium.com',
  },
];

export class LauncherMenu extends ProfileContext.withContext(LinkList) {
  async connectedCallback() {
    this.render();
    return super.connectedCallback();
  }
  contextChangedCallback() {
    this.render();
  }

  async render() {
    let authenticated = await this.context?.authenticated;
    let profile = null;
    let products = [];
    if (this.context && authenticated) {
      profile = await this.context.getProfile();
      products = profile.products?.map((product) => {
        return {
          label: product.title,
          href: product.url,
        };
      });
    }

    const myProducts = this.drawList(
      'My Products',
      this.alphabetizeByLabel(products),
    );
    const ciriumApps = this.drawList(
      'Cirium Apps',
      this.alphabetizeByLabel(this.filterList(appLinks, products, false)),
    );
    const ciriumPages = authenticated
      ? this.drawList('Cirium.com', ciriumLinks)
      : '';

    this.innerHTML = `${myProducts}${ciriumApps}${ciriumPages}`;
  }

  alphabetizeByLabel(list) {
    if (Array.isArray(list) && list.length) {
      return list.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }
    return list;
  }
  drawList(title, products) {
    if (products && products.length) {
      const header = title
        ? `<af-link-list-heading>${title}</af-link-list-heading>`
        : '';
      const links = products
        .map((app) => {
          return `<af-link-list-item href="${app.href}">${app.label}</af-link-list-item>`;
        })
        .join('');
      return `${header}${links}`;
    }
    return '';
  }
  filterList(productList = [], omitList = [], entitled) {
    const omitKeys = omitList?.map((p) => p.label);
    if (omitKeys) {
      return productList
        .map((p) => {
          const { entitledHref, href, label } = p;
          const calculatedHref = entitled ? entitledHref || href : href;
          return { label, href: calculatedHref };
        })
        .filter((p) => {
          return !omitKeys.includes(p.label);
        });
    }

    return productList;
  }
}

export const helpLinks = [
  {
    label: 'Cirium Help Desk',
    href: 'https://helpdesk.cirium.com/',
  },
  {
    label: 'Contact Support',
    href: 'https://helpdesk.cirium.com/s/contactsupport',
  },
  {
    label: 'Product Status',
    href: 'https://status.cirium.com/',
  },
];

export class HelpMenu extends LinkList {
  constructor() {
    super();
    this.innerHTML = helpLinks
      .map((app) => {
        return `<af-link-list-item href="${app.href}">${app.label}</af-link-list-item>`;
      })
      .join('');
  }
}

const accountMenuStyle = constructStyle(`
    ::slotted(.af-account-menu__info),
    ::slotted(.af-account-menu__info-header),
    ::slotted(.af-account-menu__info-subheader){
      font-family: var(--font-family-montserrat) !important;
      display: block;
      text-align: center;
      padding: var(--airframe-space-xl);
      padding-top: var(--airframe-space-xs) !important;
    }
`);

export class AccountMenu extends ProfileContext.withContext(BaseElement) {
  constructor() {
    super();
    this.adoptStyle(accountMenuStyle);
    this.importTemplate(() => `<slot></slot>`);
  }

  contextChangedCallback() {
    this.render();
  }

  displayProfileName(user) {
    const firstName = user?.name?.first || '';
    const lastName = user?.name?.last || '';

    const displayName = [];
    if (firstName) displayName.push(firstName);
    if (lastName) displayName.push(lastName);
    return displayName.join(' ');
  }

  async render() {
    const ctx = this.context;

    let authenticated = ctx ? await ctx.authenticated : false;
    let profile = null;
    let logoutUrl = '';
    let signupUrl = '';
    let loginUrl = '';

    if (ctx) {
      if (authenticated) {
        profile = await ctx.getProfile();
        logoutUrl = await ctx.logoutUrl;
      }
      signupUrl = await ctx.signupUrl;
      loginUrl = await ctx.loginUrl;
    }
    if (ctx && logoutUrl) {
      this.innerHTML = `
        ${
          profile && (profile.name || profile.company)
            ? `
              <div class="af-account-menu__info">
                <h6 class="heading6 af-account-menu__info-header">${this.displayProfileName(profile)}</h6>
                <span class="footer footnote af-account-menu__info-subheader">${profile.company}</span>
              </div>
              <hr/>
            `
            : ''
        }
        <af-link-list>
          <af-link-list-item href="${
            config.myCiriumHost
          }/flightdeck">Account Flightdeck</af-link-list-item>
          <af-link-list-item href="${
            config.myCiriumHost
          }/my-account/account-overview">Manage account</af-link-list-item>
          <af-link-list-item href="${logoutUrl}">Logout<af-svg-icon-logout></af-svg-icon-logout></af-link-list-item>
        </af-link-list>
      `;
    } else if (ctx && loginUrl && signupUrl) {
      this.innerHTML = `
        <af-link-list>
            <af-link-list-item href="${loginUrl}">Login</af-link-list-item>
            <af-link-list-item href="${signupUrl}">Signup</af-link-list-item>
        </af-link-list>
      `;
    } else {
      this.innerHTML = `
        <af-link-list>
          <af-link-list-item href="">Loading...</af-link-list-item>
        </af-link-list>
      `;
    }
  }
  async connectedCallback() {
    this.render();
    return super.connectedCallback();
  }
}

window.customElements.define('af-global-header-dropdown', Dropdown);
window.customElements.define('af-launcher-dropdown', LauncherDropdown);
window.customElements.define('af-launcher-menu', LauncherMenu);
window.customElements.define('af-help-dropdown', HelpDropdown);
window.customElements.define('af-help-menu', HelpMenu);
window.customElements.define('af-account-dropdown', AccountDropdown);
window.customElements.define('af-account-menu', AccountMenu);
